.default-animation {
  transition: all 0.5s ease;
}

// Pulse Animation
.pulse {
  animation-name: pulse_animation;
  animation-duration: 2000ms;
  transform-origin:70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulse_animation {
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.08); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.05); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}
